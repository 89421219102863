import React, { useState } from "react";
import Layout from "../../../components/globalComponents/Layout/Layout";
import { Box, Typography, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Followers from "./Followers";
import Following from "./Following";
import Invitation from "./Invitation";
// import ProfilePicture from "../../../components/globalComponents/ProfilePicture";

const Connections = () => {
  const [value, setValue] = useState("1");
  // const isMobile = useMediaQuery("(max-width:600px)"); // Define your breakpoint for mobile
  //change tab value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout title={"Your Social Network | SMAC"}>
      {/*  */}
      {/*Followers or Cards section */}
      <Box
        sx={{
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
                padding: "1.5rem .5rem",
              }}
            >
              <TabList
                onChange={handleChange}
                sx={{
                  "& span": {
                    position: "block",
                    display: "none",
                    height: "0px",
                  },
                  boxShadow: " 0px 0px 4px 0px #00000040",
                  borderRadius: "12px",
                  background: "white",
                  width: "100%",
                  maxWidth: "683px",
                  display: "flex",
                  padding: "8px 10px",
                }}
              >
                {/* tab buttons? */}
                <Tab label={"Followers"} value="1" />
                <Tab label={"Following"} value="2" />
                <Tab label={"Invitation"} value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box>
                <Followers />
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Following />
            </TabPanel>
            <TabPanel value="3">
              <Invitation />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Layout>
  );
};

export default Connections;
