import React, { useEffect, useState } from "react";
import CourseCard from "../Services/CourseCard";
import theme from "../../theme";
import { Box, Typography } from "@mui/material";
import useFetch from "../../features/hooks/useFetch";
import Marquee from "react-fast-marquee";

const PopularCourses = () => {
  const [trendingCourses, setTrendingCourses] = useState([]);
  const { fetchData } = useFetch();
  const fetchTrendingCourses = () => {
    //this api to be changed if the the trending post api is created
    try {
      fetchData("/api/course?limit=6", undefined, (res) => {
        setTrendingCourses(res?.data);
      });
    } catch (err) {
      console.log(err, "error while getting courses");
    }
  };
  useEffect(() => {
    fetchTrendingCourses();
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        gap: "1.5rem",
        mt: "5rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "clamp(2.1875rem, 1.6875rem + 2.5vw, 4.6875rem)",
          fontWeight: 700,
          lineHeight: "86.24px",
          textAlign: "center",
          color: "#000000",
        }}
      >
        Top Courses from Leading Experts
      </Typography>

      <Typography variant="uploadForm" maxWidth={864} textAlign={"center"}>
        SMAC is{" "}
        <span style={{ fontWeight: 600, color: theme.palette.primary.main }}>
          your one-stop solution
        </span>{" "}
        for all your development needs!
      </Typography>

      <Marquee pauseOnHover style={{ padding: "1.5rem 0rem" }}>
        {trendingCourses.map((course) => {
          return (
            <div
              style={{
                marginLeft: "1rem",
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "1rem",
              }}
              key={course.courseId}
            >
              <CourseCard course={course} />
            </div>
          );
        })}
      </Marquee>
    </Box>
  );
};

export default PopularCourses;
